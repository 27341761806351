import React from 'react';
import ReactDOM from 'react-dom/client'; // Use createRoot from 'react-dom/client'
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import './index.css';
import App from './App';

// Define Solana wallets
const wallets = [new PhantomWalletAdapter()];

// Get the root DOM element
const container = document.getElementById('root');

// Create a React root using createRoot
const root = ReactDOM.createRoot(container);

// Render the app inside the root without React.StrictMode
root.render(
    <TonConnectUIProvider manifestUrl="YOUR_MANIFEST_URL" theme={THEME.DARK}>
        {/* Solana Connection Provider */}
        <ConnectionProvider endpoint="https://solana-mainnet.g.alchemy.com/v2/ztZKMssxyRYI-GGVfqPZPddIPXEsUQro">
            {/* Solana Wallet Provider */}
            <WalletProvider wallets={wallets} autoConnect>
                {/* Solana Wallet Modal Provider */}
                <WalletModalProvider>
                    {/* Your Main Application */}
                    <App />
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    </TonConnectUIProvider>
);
