import React, { useState, useEffect } from 'react';
import axios from 'axios';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import 'react-toastify/dist/ReactToastify.css';
import './Ref.css';
import Loading from '../components/Loading';

const Ref = () => {
    const userId = localStorage.getItem("userId");
    const [referralLink, setReferralLink] = useState('');
    const [referredUsers, setReferredUsers] = useState([]);
    const [referredCount, setReferredCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [coins, setCoins] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.BackButton.onClick(() => {
            window.history.back();
        });
    }

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const { data } = await axios.get(`/api/users/${userId}`);
                setCoins(data.coins);
                setReferralLink(`https://t.me/Cryptoluxebot_bot?start=${data.referralCode}`);
                await fetchReferralData(data.referralCode, 1);
            } catch (err) {
                console.error('Failed to fetch user details', err);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userId]);

    const fetchReferralData = async (referralCode, page) => {
        try {
            console.log(`Fetching page ${page}`);
            const { data } = await axios.get(`/api/referrals/${referralCode}?page=${page}`);
            const usersWithAvatars = await Promise.all(
                data.users.map(async (user) => {
                    try {
                        const resAvatar = await axios.get(`/api/users/avatar/${user.userId}`, {
                            responseType: 'blob',
                        });
                        if (resAvatar.data) {
                            const objectUrl = URL.createObjectURL(resAvatar.data);
                            return { ...user, avatar: objectUrl };
                        }
                    } catch (err) {
                        console.error('Failed to fetch avatar', err);
                    }
                    return user;
                })
            );
            setReferredUsers(prevUsers => [...prevUsers, ...usersWithAvatars]);
            setReferredCount(data.count);
            if (usersWithAvatars.length === 0 || usersWithAvatars.length < 10) {
                setHasMore(false);
            }
        } catch (err) {
            console.error('Failed to fetch referral details', err);
            toast.error('Failed to fetch referral details');
        }
    };

    const loadMoreReferrals = () => {
        console.log('Loading more referrals');
        const nextPage = page + 1;
        setPage(nextPage);
        fetchReferralData(referralLink.split('=')[1], nextPage);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink);
        toast.success('Referral link copied to clipboard');
    };

    const handleLeaderBoardClick = () => {
        navigate('/leaderboard', {
            replace: true
        });
    };

    const formatNumber = (number) => {
        if (number >= 1000000000) {
            return (number / 1000000000).toFixed(3) + ' BS';
        } else if (number >= 1000000)  {
            return (number / 1000000).toFixed(3) + ' M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + ' K';
        }
        return new Intl.NumberFormat('en-US').format(number);
    };

    return (
        <div className="ref">
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="frens-main">
                        <div className="frens-count">
                            <span>{referredCount} Frens</span>
                        </div>
                        <div onClick={handleLeaderBoardClick} className="frens-total-earn">
                            <div className="frens-total-count">
                                <span>{formatNumber(coins)}</span>
                                <img src="/icons/coin.png" width="20px" alt=""/>
                            </div>
                            <div className="bold">
                                <span className="white">Top 50</span>
                                <span className="leaders">leaders</span>
                            </div>
                            <div>
                                <svg width="23px" height="23px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 5L14.15 10C14.4237 10.2563 14.6419 10.5659 14.791 10.9099C14.9402 11.2539 15.0171 11.625 15.0171 12C15.0171 12.375 14.9402 12.7458 14.791 13.0898C14.6419 13.4339 14.4237 13.7437 14.15 14L9 19"
                                        stroke="#ffffff"
                                        opacity="0.4"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="frens-help">
                        <div className="frens-help-title">
                            <span>Invite frens to get bonuses</span>
                        </div>
                        <div className="frens-help-box">
                            <div className="frens-help-content">
                                <div className="frens-help-image">
                                    <img src="/icons/golden.png" width="50px" alt=""/>
                                </div>
                                <div className="fren-help-text bold white">
                                    <h5>Invite fren</h5>
                                    <div className="white">
                                        <img src="/icons/coin.png" width="15px" alt=""/>
                                        <span className="gold">+100,000</span>
                                        <span>for you and fren</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="frens-list allow-touch" id="scrollableDiv">
                        <div className="frens-list-title">
                            <span>Frens List</span>
                        </div>
                        {referredUsers.length > 0 ? (
                            <InfiniteScroll
                                dataLength={referredUsers.length}
                                next={loadMoreReferrals}
                                hasMore={hasMore}
                                loader={<div className="loader" key={0}>Loading ...</div>}
                                scrollableTarget="scrollableDiv"
                                endMessage={
                                    <p className="white" style={{ textAlign: 'center', marginTop: '10px' }}>
                                        <b>No more referrals</b>
                                    </p>
                                }
                            >
                                <div className="frens-list-box">
                                    {referredUsers.map(user => (
                                        <div className="frens-data" key={user.userId}>
                                            <div className="frens-profile">
                                                <div className="frens-profile-photo">
                                                    <img src={user.avatar} width="56" alt=""/>
                                                </div>
                                                <div className="frens-profile-data">
                                                    <h4 className="white">{user.first_name}</h4>
                                                    <div>
                                                        <img src="/icons/3d-trophy.png" width="15px" alt=""/>
                                                        <span className="white">{user.level}</span>
                                                        <span>•</span>
                                                        {/*<img src="/icons/coin.png" width="15px" alt=""/>*/}
                                                        {/*<span>{formatNumber(user.coins)}</span>*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="frens-data-count bold">
                                                <span>{formatNumber(user.coins)}</span>
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M9 5L14.15 10C14.4237 10.2563 14.6419 10.5659 14.791 10.9099C14.9402 11.2539 15.0171 11.625 15.0171 12C15.0171 12.375 14.9402 12.7458 14.791 13.0898C14.6419 13.4339 14.4237 13.7437 14.15 14L9 19"
                                                        stroke="#ffffff"
                                                        opacity="0.4"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </InfiniteScroll>
                        ) : (
                            <div className="frens-data">
                                <div className="frens-profile">
                                    <div className="frens-profile-data white">
                                        <h4>You don't have referrals 😢</h4>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="frens-invite">
                        <div className="frens-invite-button">
                            <input type="hidden" value={referralLink} readOnly />
                            <span onClick={copyToClipboard}>Invite a fren</span>
                        </div>
                    </div>
                    {/*<QRCode value={referralLink} />*/}
                </>
            )}
        </div>
    );
};

export default Ref;
