// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pay_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.pay {
    text-align: center;
}

.wallet-adapter-button {
    margin: 0 auto;
}

.pay p {
    padding: 10px;
}

.pay .submit-button {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 46px;
    line-height: 48px;
    padding: 0 70px;
    border-radius: 4px;
    margin: 0 auto;
}

.disabled-button {
    background: gray !important;
    color: white;
    cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Pay.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,WAAW;IACX,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,gFAAgF;IAChF,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,2BAA2B;IAC3B,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".pay_container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 50px;\n}\n\n.pay {\n    text-align: center;\n}\n\n.wallet-adapter-button {\n    margin: 0 auto;\n}\n\n.pay p {\n    padding: 10px;\n}\n\n.pay .submit-button {\n    background-color: transparent;\n    border: none;\n    color: #fff;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;\n    font-size: 16px;\n    font-weight: 600;\n    height: 46px;\n    line-height: 48px;\n    padding: 0 70px;\n    border-radius: 4px;\n    margin: 0 auto;\n}\n\n.disabled-button {\n    background: gray !important;\n    color: white;\n    cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
